import heroImage from './hero-image.png';
import appStoreDownloadImage from './appStoreLogoRoundedWhiteBorder.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={heroImage} className="Hero-image" alt="alba-mind-hero" />
        <p style={{fontWeight: 700}}>
          The mental health app you'll actually enjoy 🌅
        </p>
        <a href="https://apps.apple.com/gb/app/alba-mind/id1548304572"
           rel="noopener noreferrer">
        <img src={appStoreDownloadImage}
             className="App-store-download-image"
             alt="app-store-download"
             />
        </a>
      </header>
    </div>
  );
}

export default App;
